




























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultDetail from './DefaultDetail.vue'
import NotifyDetail from './NotifyDetail.vue'
import EmployeeSelect from '@/components/Select/EmployeeSelect.vue'
import DepartmentSelect from '@/components/Select/DepartmentSelect.vue'
import { variableOptions } from '@/utils/options'
@Component({
  inject: ['i18n'],
  components: {
    DefaultDetail,
    NotifyDetail,
    EmployeeSelect,
    DepartmentSelect
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  model!: any

  @Prop({
    default() {
      return []
    }
  })
  tasks!: Array<any>

  @Prop({
    default() {
      return null
    }
  })
  onChange!: Function

  @Prop({ default: false }) readOnly!: Boolean

  @Prop({
    default() {
      return []
    }
  })
  templates!: Array<any>

  @Prop({
    default() {
      return []
    }
  })
  templatesBase!: Array<any>

  variableOptions = variableOptions

  roleList = []

  getPersons(e: any) {
    if (e === undefined || e === null || e.length <= 1) {
      this.onChange('isCounterSign', false)
    }
  }

  assignmentType() {
    this.onChange('isCounterSign', false)
  }
}
