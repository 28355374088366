
































import { Component, Vue, Prop } from 'vue-property-decorator'
import SingleSelectDropDown from '@/components/DropDown/SingleSelectDropDown.vue'
import InputDropDown from '@/components/DropDown/InputDropDown.vue'
import { flowRuleOptions } from '@/utils/options'

@Component({ components: { SingleSelectDropDown, InputDropDown } })
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  model!: any

  @Prop({
    default() {
      return null
    }
  })
  onChange!: Function

  @Prop({ default: false }) readOnly!: Boolean

  id = 1000

  created() {
    if (this.model && this.model.ruleData) {
      this.data = this.model.ruleData
    }
  }

  private flowRuleOptions = flowRuleOptions

  private data = [
    {
      compare: '=',
      logic: 'and',
      target: '',
      value: ''
    }
  ]

  private defaultProps = {
    children: 'children',
    label: 'label'
  }

  private compareOptions = [
    { label: '=', value: '=' },
    { label: '<', value: '<' },
    { label: '>', value: '>' },
    { label: 'in', value: 'in' },
    { label: 'not in', value: 'not in' },
    { label: 'like', value: 'like' },
    { label: 'not like', value: 'not like' }
  ]

  private append(index: number) {
    this.data.splice(index + 1, 0, {
      compare: '=',
      logic: 'and',
      target: '',
      value: ''
    })
  }

  private remove(index: number) {
    this.data.splice(index, 1)
  }

  private submit() {
    let rule = ''
    const effectiveItems = this.data.filter((item: any) => item.target && item.compare && item.value)
    for (const [idx, item] of effectiveItems.entries()) {
      const value = Array.isArray(item.value) ? item.value : `"${item.value}"`
      rule += ` ${item.target} ${item.compare} ${value} `
      if (idx !== this.data.length - 1) {
        rule += ` ${item.logic} `
      }
    }
    this.$emit('submit', { rule: rule, data: effectiveItems })
    this.$message.success('已填充到条件表达式')
  }
}
