var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-clazz":_vm.model.clazz}},[_c('div',{staticClass:"panelTitle"},[_vm._v("处理节点")]),_c('div',{staticClass:"panelBody"},[_c('default-detail',{attrs:{"model":_vm.model,"on-change":_vm.onChange,"read-only":_vm.readOnly}}),_c('div',{staticClass:"panelRow"},[_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.i18n['userTask.assignType'])+"：")]),_c('el-select',{staticStyle:{"width":"90%","font-size":"12px"},attrs:{"size":"small","placeholder":_vm.i18n['userTask.assignType.placeholder'],"value":_vm.model.assignType,"disabled":_vm.readOnly},on:{"change":function (e) {
            _vm.onChange('assignValue', [])
            _vm.onChange('assignType', e)
            _vm.assignmentType()
          }}},[_c('el-option',{key:"person",attrs:{"value":"person","label":_vm.i18n['userTask.assignType.person']}}),_c('el-option',{key:"department",attrs:{"value":"department","label":_vm.i18n['userTask.assignType.department']}}),_c('el-option',{key:"role",attrs:{"value":"role","label":"角色"}})],1)],1),(_vm.model.assignType === 'person')?_c('div',{staticClass:"panelRow"},[_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.i18n['userTask.assignType.person.title'])+"： ")]),_c('EmployeeSelect',{staticStyle:{"width":"90%","font-size":"12px"},attrs:{"size":"small","placeholder":_vm.i18n['userTask.assignType.person.placeholder'],"disabled":_vm.readOnly,"multiple":true,"filterable":true},on:{"change":function (e) {
            _vm.onChange('assignValue', e)
            _vm.getPersons(e)
          }},model:{value:(_vm.model.assignValue),callback:function ($$v) {_vm.$set(_vm.model, "assignValue", $$v)},expression:"model.assignValue"}})],1):(_vm.model.assignType === 'department')?_c('div',{staticClass:"panelRow"},[_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.i18n['userTask.assignType.department.title'])+"： ")]),_c('DepartmentSelect',{staticStyle:{"width":"90%","font-size":"12px"},attrs:{"size":"small","placeholder":_vm.i18n['userTask.assignType.department.placeholder'],"value":_vm.model.assignValue,"disabled":_vm.readOnly,"multiple":true,"filterable":true},on:{"change":function (e) {
            _vm.onChange('assignValue', e)
            _vm.getPersons(e)
          }}})],1):(_vm.model.assignType === 'variable')?_c('div',{staticClass:"panelRow"},[_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.i18n['userTask.assignType.variable.title'])+"： ")]),_c('el-select',{staticStyle:{"width":"90%","font-size":"12px"},attrs:{"size":"small","placeholder":_vm.i18n['userTask.assignType.variable.placeholder'],"disabled":_vm.readOnly,"multiple":true,"filterable":true},on:{"change":function (e) {
            _vm.onChange('assignValue', e)
            _vm.getPersons(e)
          }},model:{value:(_vm.model.assignValue),callback:function ($$v) {_vm.$set(_vm.model, "assignValue", $$v)},expression:"model.assignValue"}},_vm._l((_vm.variableOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1):(_vm.model.assignType === 'rpc' || _vm.model.assignType === 'rpc-check')?_c('div',{staticClass:"panelRow"},[_c('div',{staticClass:"panelRow"},[_vm._m(0),_c('el-input',{attrs:{"disabled":_vm.readOnly,"placeholder":"请输入调用的服务的名称"},on:{"change":function (e) {
              _vm.onChange('serviceKey', e)
            }},model:{value:(_vm.model.serviceKey),callback:function ($$v) {_vm.$set(_vm.model, "serviceKey", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.serviceKey"}})],1),_c('div',{staticClass:"panelRow"},[_vm._m(1),_c('el-input',{attrs:{"disabled":_vm.readOnly,"placeholder":"请输入调用的服务的方法名称"},on:{"change":function (e) {
              _vm.onChange('serviceMethod', e)
            }},model:{value:(_vm.model.serviceMethod),callback:function ($$v) {_vm.$set(_vm.model, "serviceMethod", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"model.serviceMethod"}})],1),_c('div',{staticClass:"panelRow"},[_c('el-button',{staticStyle:{"margin-left":"70%"},attrs:{"size":"mini"},on:{"click":function () { return _vm.$message.info('待完善测试代码'); }}},[_vm._v(" 测试 ")])],1)]):_vm._e(),_c('div',{staticClass:"panelRow"},[_c('el-checkbox',{attrs:{"size":"small","disabled":_vm.model.assignValue === undefined || _vm.model.assignValue === null || _vm.model.activeOrder || _vm.readOnly,"value":_vm.model.isCounterSign},on:{"change":function (e) {
            _vm.onChange('isCounterSign', e)
          }}},[_vm._v(_vm._s(_vm.i18n['userTask.counterSign']))])],1),_c('NotifyDetail',{attrs:{"model":_vm.model,"on-change":_vm.onChange,"read-only":_vm.readOnly}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" RPC服务名称： ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" RPC服务方法： ")])}]

export { render, staticRenderFns }