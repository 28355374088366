<template>
  <div class="form-config-container">
    <el-form label-position="top">
      <el-form-item :label="$t('fm.config.form.labelPosition.title')">
        <el-radio-group v-model="data.labelPosition" size="mini">
          <el-radio-button label="left">{{ $t('fm.config.form.labelPosition.left') }}</el-radio-button>
          <el-radio-button label="right">{{ $t('fm.config.form.labelPosition.right') }}</el-radio-button>
          <el-radio-button label="top">{{ $t('fm.config.form.labelPosition.top') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('fm.config.form.labelWidth')">
        <el-input-number v-model="data.labelWidth" :min="0" :max="200" :step="10" size="mini" />
      </el-form-item>

      <el-form-item :label="$t('fm.config.form.size')">
        <el-radio-group v-model="data.size" size="mini">
          <el-radio-button label="medium">medium</el-radio-button>
          <el-radio-button label="small">small</el-radio-button>
          <el-radio-button label="mini">mini</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="显示行下划线">
        <el-checkbox v-model="data.borderLine">显示</el-checkbox>
      </el-form-item>
      <el-form-item label="RPC校验表单值">
        <el-input v-model="data.remoteServiceKey" size="mini" clearable>
          <template slot="prepend">RPC服务名</template>
        </el-input>
        <el-input v-model.trim="data.remoteServiceMethod" size="mini" clearable>
          <template slot="prepend">RPC方法名</template>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  props: ['data']
}
</script>
