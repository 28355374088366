












































































































import { Component, Vue, Prop } from 'vue-property-decorator'
// import { RoleModule } from '@/store/modules/role'

@Component({ name: 'ActionDetail' })
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  model!: any

  @Prop({
    default() {
      return null
    }
  })
  onChange!: Function

  @Prop({ default: false }) readOnly!: Boolean

  get roles() {
    // 从vuex里面获取
    // @ts-ignore
    return RoleModule.siteRoles
  }

  private dataChange() {
    this.onChange('actionData', this.model.actionData)
  }

  private deleteOne(idx: number) {
    this.model.actionData.splice(idx, 1)
  }
}
