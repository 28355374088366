interface IEdge {
  clazz: string
  source: string
  target: string
  sourceAnchor: number
  targetAnchor: number
}

interface INode {
  id: string
  clazz: string
}

export const validProcess = (processData: {
  nodes: Array<INode>
  edges: Array<IEdge>
}): string => {
  if (
    processData.nodes &&
    processData.nodes.filter((item: any) => item.clazz === 'start').length !== 1
  ) {
    return '审批流程需满足 有且只有一个开始节点'
  }
  if (
    processData.nodes &&
    processData.nodes.filter((item: any) => item.clazz === 'end').length !== 1
  ) {
    return '审批流程需满足 有且只有一个结束节点'
  }
  //   todo: 判断是否有孤立节点
  // todo：判断是否只有一条流程
  return ''
}
