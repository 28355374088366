<template>
  <div :data-clazz="model.clazz">
    <div class="panelTitle">{{ i18n["endEvent"] }}</div>
    <div class="panelBody">
      <default-detail
        :model="model"
        :on-change="onChange"
        :read-only="readOnly"
      />
      <action-detail
        :model="model"
        :on-change="onChange"
        :read-only="readOnly"
      ></action-detail>
      <notify-detail
        :model="model"
        :on-change="onChange"
        :read-only="readOnly"
      ></notify-detail>
      <StatusDetail
        :model="model"
        :on-change="onChange"
        :read-only="readOnly"
      />
      <div class="panelRow">
        <div>是否允许拒绝:</div>
        <el-switch
          v-model="model.allowReject"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="
            (e) => {
              onChange('allowReject', e);
            }
          "
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>
<script>
import DefaultDetail from "./DefaultDetail";
import NotifyDetail from "./NotifyDetail";
import ActionDetail from "./ActionDetail";
import StatusDetail from "./StatusDetail.vue";

export default {
  inject: ["i18n"],
  components: {
    DefaultDetail,
    NotifyDetail,
    ActionDetail,
    StatusDetail,
  },
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    onChange: {
      type: Function,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    templates: {
      type: Array,
      default: () => [],
    },
    templatesBase: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
