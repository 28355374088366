import { processApis } from '@/api/process'
import { Message } from 'element-ui'

export const remoteSelectFunc = async(serviceKey: string, serviceMethod: string, q: string, useEnv: boolean, form: Object) => {
  try {
    return await processApis.getChoices(q, serviceKey, serviceMethod, useEnv, form)
  } catch (e) {
    Message.error(e.message)
  }
}
