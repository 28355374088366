import { render, staticRenderFns } from "./NotifyDetail.vue?vue&type=template&id=53ae5df5&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./NotifyDetail.vue?vue&type=script&lang=ts&"
export * from "./NotifyDetail.vue?vue&type=script&lang=ts&"
import style0 from "./NotifyDetail.vue?vue&type=style&index=0&id=53ae5df5&prod&scoped=true&style=scss&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ae5df5",
  null
  
)

export default component.exports