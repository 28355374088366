

























































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import FmUpload from "./Upload/index.vue";
import FileUpload from "./Upload/file.vue";
// @ts-ignore
import InstrumentFile from "./Upload/InstrumentFile";
import Markdown from "@/components/Markdown/index.vue";
import EmployeeSelect from "@/components/Select/EmployeeSelect.vue";
import InstrumentSelect from "@/components/Select/InstrumentSelect.vue";
import DepartmentSelect from "@/components/Select/DepartmentSelect.vue";
import InstrumentTmpSelect from "@/components/Select/InstrumentTmpSelect.vue";
import InstrumentAdjustSelect from "@/components/Select/InstrumentAdjustSelect.vue";
import InstrumentCreateTable from "@/components/Select/InstrumentCreateTable.vue";

@Component({
  name: "WidgetFormFields",
  components: {
    FmUpload,
    InstrumentFile,
    FileUpload,
    Markdown,
    EmployeeSelect,
    InstrumentSelect,
    DepartmentSelect,
    InstrumentAdjustSelect,
    InstrumentTmpSelect,
    InstrumentCreateTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) element!: any;
  @Prop() orderId: any;
}
