var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element && _vm.element.key)?_c('el-form-item',{staticClass:"widget-view",class:{
    active: _vm.selectWidget.key === _vm.element.key,
    'is-req': _vm.element.options.required,
  },attrs:{"label-width":_vm.isLabel || !_vm.element.options.labelWidthStatus
      ? '0px'
      : _vm.elementLabelWidth + 'px',"label":_vm.isLabel || _vm.element.type === 'divider' || !_vm.element.options.labelWidthStatus
      ? ''
      : _vm.element.name},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleSelectWidget(_vm.index)}}},[(_vm.isTable)?_c('div',[_c('el-table',{attrs:{"data":[_vm.element],"border":""}},[_c('el-table-column',{attrs:{"label":_vm.element.name}},[_c('widget-form-fields',{attrs:{"orderId":_vm.orderId,"element":_vm.element}})],1)],1)],1):_c('div',[_c('widget-form-fields',{attrs:{"orderId":_vm.orderId,"element":_vm.element}})],1),(_vm.selectWidget.key === _vm.element.key)?_c('div',{staticClass:"widget-view-action"},[_c('i',{staticClass:"iconfont icon-icon_clone",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetClone(_vm.index)}}}),_c('i',{staticClass:"iconfont icon-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetDelete(_vm.index)}}})]):_vm._e(),(_vm.selectWidget.key === _vm.element.key)?_c('div',{staticClass:"widget-view-drag"},[_c('i',{staticClass:"iconfont icon-drag drag-widget"})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }