export const formMarkingToolbar = {
  basicFields: [
    'input',
    'textarea',
    'number',
    'radio',
    'checkbox',
    'time',
    'date',
    'color',
    'select',
    'switch',
    'slider',
    'text',
    'env-checkbox',
    'employee-select',
    'instrument-select',
    'department-select',
    'instrument-tmp-select',
    'instrument-adjust-select',
    'instrument-create-table',
  ],
  advanceFields: ['editor', 'markdown', 'subform', 'cascader', 'file', 'instrument-file']
}
// 审批流节点自定义变量的映射关系
export const variableOptions = [
  {
    value: '$creator',
    label: '创建者'
  },
  {
    value: '$creator-leader',
    label: '创建者直属领导'
  },
  {
    value: '$pre-leader',
    label: '前一节点直属领导'
  }
]
// 审批流节点层级的选项
export const levelOptions = [
  {
    value: '$ceo-1',
    label: 'CEO-1'
  },
  {
    value: '$ceo-2',
    label: 'CEO-2'
  },
  {
    value: '$ceo-3',
    label: 'CEO-3'
  },
  {
    value: '$ceo-4',
    label: 'CEO-4'
  }
]

// 流程中可以使用的变量
export const flowRuleOptions = [
  {
    value: '@creator',
    label: '创建者'
  },
  {
    value: '@pre-leader',
    label: '前一节点直属领导'
  }
]
