































































import { Component, Vue, Prop } from "vue-property-decorator";
import DefaultDetail from "./DefaultDetail.vue";
import NotifyDetail from "./NotifyDetail.vue";
import StatusDetail from "./StatusDetail.vue";

@Component({
  inject: ["i18n"],
  components: {
    DefaultDetail,
    NotifyDetail,
    StatusDetail,
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  model!: any;

  @Prop({
    default() {
      return [];
    },
  })
  tasks!: Array<any>;

  @Prop({
    default() {
      return null;
    },
  })
  onChange!: Function;

  @Prop({ default: false }) readOnly!: Boolean;

  @Prop({
    default() {
      return [];
    },
  })
  templates!: Array<any>;

  @Prop({
    default() {
      return [];
    },
  })
  templatesBase!: Array<any>;

  @Prop({
    default() {
      return [];
    },
  })
  users!: Array<any>;
}
