<template>
  <div
    class="detailPanel"
    :style="{height: height + 'px'}"
    style="overflow-y: auto; padding-bottom: 10px;"
  >
    <user-task-detail
      v-if="model.clazz === 'userTask'"
      :model="model"
      :on-change="onChange"
      :read-only="readOnly"
      :users="users"
      :roles="roles"
      :groups="groups"
      :departments="departments"
      :tasks="tasks"
      :templates="templates"
      :templates-base="templatesBase"
    />
    <script-task-detail
      v-else-if="model.clazz === 'scriptTask'"
      :model="model"
      :on-change="onChange"
      :read-only="readOnly"
      :tasks="tasks"
    />
    <handle-node-detail
      v-else-if="model.clazz === 'receiveTask'"
      :model="model"
      :on-change="onChange"
      :read-only="readOnly"
      :users="users"
      :roles="roles"
      :groups="groups"
      :departments="departments"
      :tasks="tasks"
      :templates="templates"
      :templates-base="templatesBase"
    />
    <gateway-detail
      v-else-if="
        model.clazz === 'gateway' ||
          model.clazz === 'exclusiveGateway' ||
          model.clazz === 'parallelGateway' ||
          model.clazz === 'inclusiveGateway'
      "
      :model="model"
      :on-change="onChange"
      :read-only="readOnly"
    />
    <start-event-detail
      v-else-if="model.clazz === 'start'"
      :model="model"
      :users="users"
      :on-change="onChange"
      :read-only="readOnly"
      :tasks="tasks"
      :templates="templates"
      :templates-base="templatesBase"
    />
    <end-event-detail
      v-else-if="model.clazz === 'end'"
      :model="model"
      :on-change="onChange"
      :read-only="readOnly"
      :tasks="tasks"
      :templates="templates"
      :templates-base="templatesBase"
    />
    <flow-detail
      v-else-if="model.clazz === 'flow'"
      :model="model"
      :on-change="onChange"
      :read-only="readOnly"
    />
  </div>
</template>
<script>
import UserTaskDetail from './UserTaskDetail'
import ScriptTaskDetail from './ScriptTaskDetail'
import HandleNodeDetail from './HandleNodeDetail'
import GatewayDetail from './GatewayDetail'
import StartEventDetail from './StartEventDetail'
import EndEventDetail from './EndEventDetail'
import FlowDetail from './FlowDetail'
export default {
  inject: ['i18n'],
  components: {
    UserTaskDetail,
    ScriptTaskDetail,
    HandleNodeDetail,
    GatewayDetail,
    StartEventDetail,
    EndEventDetail,
    FlowDetail
  },
  props: {
    height: {
      type: Number,
      default: 800
    },
    model: {
      type: Object,
      default: () => ({})
    },
    signalDefs: {
      type: Array,
      default: () => []
    },
    messageDefs: {
      type: Array,
      default: () => []
    },
    onChange: {
      type: Function,
      default: () => null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    },
    roles: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    },
    departments: {
      type: Array,
      default: () => []
    },
    tasks: {
      type: Array,
      default: () => []
    },
    templates: {
      type: Array,
      default: () => []
    },
    templatesBase: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss">
.detailPanel {
  height: 100%;
  background: #f0f2f5;
  flex: 0 0 auto;
  float: left;
  width: 20%;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  .panelTitle {
    text-align: left;
    height: 32px;
    padding-left: 12px;
    color: #000;
    line-height: 28px;
    background: #ebeef2;
    border-bottom: 1px solid #dce3e8;
  }

  .panelBody {
    .panelRow {
      text-align: left;
      display: inline-block;
      font-size: 12px;
      width: 100%;
      padding: 5px 12px;
    }
  }
}
</style>
