




























































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { processApis, IProcessDetail, IFormDetail } from '@/api/process'
import { validProcess } from '@/utils/valid-process'
import { formMarkingToolbar } from '@/utils/options'
import { handleClipboard } from '@/utils/clipboard'
import { remoteSelectFunc } from '@/utils/functions'

// @ts-ignore
import Wfd from '@/components/wfd/components/Wfd.vue'
// @ts-ignore
import { GenerateForm, MakingForm } from '@/components/VueFormMaking'
import '@/components/VueFormMaking/styles/FormMaking.css'
import ace from 'ace-builds'
import 'ace-builds/webpack-resolver'
Vue.use(ace as any)
Vue.use(MakingForm as any)
Vue.use(GenerateForm as any)

@Component({ components: { Wfd } })
export default class extends Vue {
  // @ts-ignore
  @Ref('makingform') makingform!: MakingForm
  // @ts-ignore
  @Ref('wfd') wfd!: Wfd
  private formMarkingToolbar = formMarkingToolbar
  private dialogVisible = false
  private updateLoading = false
  private processObj: IProcessDetail | null = null
  private form: IFormDetail | null = null
  private remoteSelectFunc = remoteSelectFunc

  private wfdRefreshTag = true
  private importVisible = false
  private importData = ''

  private readonly rules = {
    name: [{ required: true, message: '请输入模版名称', trigger: 'blur' }],
    remarks: [{ required: true, message: '请输入模板说明', trigger: 'blur' }]
  }

  private refreshForm(data: Object) {
    this.$nextTick(() => {
      this.makingform.setJSON(data)
    })
  }

  private refreshWfd() {
    this.wfdRefreshTag = false
    this.$nextTick(() => {
      this.wfdRefreshTag = true
    })
  }

  // confirmNotify() {
  //   this.$confirm('是否需要通知审批人进行发布此次修改?', '提示', {
  //     confirmButtonText: '通知管理员',
  //     cancelButtonText: '不需要',
  //     type: 'warning'
  //   })
  //     .then(() => {
  //       this.updateProcess(1)
  //     })
  //     .catch(() => {
  //       this.updateProcess(0)
  //     })
  // }

  private async updateProcess() {
    if (this.processObj) {
      if (!(this.processObj.name && this.processObj.remarks)) {
        this.$message.error('审批单名称和描述必须存在')
        return
      }

      // 验证流程的数据
      const processData = this.wfd.graph.save()
      const error = validProcess(processData)
      if (error) {
        this.$message.error(error)
        return
      }
      // 提交数据
      this.updateLoading = true
      // 这里必须这样写，不然数据刷新会有问题。wdf的未知bug
      processApis
        .updateProcess(this.processObj.id, {
          ...this.processObj,
          notify: 0,
          structure: processData
        })
        .then(r => {
          this.$notify.success('更新流程成功')
          this.processObj = r
          this.refreshWfd()
          //   this.processObj = r
          //   this.refreshWfd()
        })
        .catch(err => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.updateLoading = false
        })
    }
  }

  private async updateForm() {
    if (this.form) {
      const formData = this.makingform.getJSON()
      this.form.structure = formData
      // 提交数据
      this.updateLoading = true
      processApis
        .updateForm(this.form.id, this.form)
        .then(() => {
          this.$notify.success('更新表单成功')
          //   this.form = r
          //   this.refreshForm(r.structure)
        })
        .catch(err => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.updateLoading = false
        })
    }
  }

  private async getProcess(processId: number) {
    try {
      this.processObj = await processApis.getProcess(processId)
      this.refreshWfd()
      console.info(33333)
      console.info(this.processObj.id)
      console.info(this.processObj.formId)
      this.form = await processApis.getForm(this.processObj.formId)
      this.refreshForm(this.form.structure)
    } catch (err) {
      this.$message.error(err.message)
    }
  }

  created() {
    this.getProcess(this.processId)
    this.dialogVisible = true
  }

  get processId(): number {
    if (this.$route.query.processId) {
      return parseInt(this.$route.query.processId as string)
    }
    return 0
  }

  private closed() {
    this.form = null
    this.processObj = null
  }

  private exportProcess($event: any) {
    handleClipboard(JSON.stringify(this.wfd.graph.save(), null, 4), $event)
  }

  private importProcess() {
    if (this.processObj) {
      this.processObj.structure = JSON.parse(this.importData)
      this.refreshWfd()
      this.importData = ''
      this.importVisible = false
    }
  }
}
