<template>
  <div>
    <div class="panelRow">
      <div><span style="color: red">*</span> {{ i18n['label'] }}：</div>
      <el-input
        style="width:90%; font-size:12px"
        :disabled="readOnly"
        :value="model.label"
        size="small"
        @input="
          value => {
            onChange('label', value)
          }
        "
      />
    </div>
    <div class="panelRow">
      <div><span style="color: red">*</span> 描述：</div>
      <el-input
        style="width:90%; font-size:12px"
        :disabled="readOnly"
        :value="model.description"
        size="small"
        @input="
          value => {
            onChange('description', value)
          }
        "
      />
    </div>
    <div class="panelRow">
      <el-checkbox
        size="small"
        :disabled="readOnly"
        :value="!!model.isHideNode"
        @change="value => onChange('isHideNode', value)"
        >隐藏节点</el-checkbox
      >
      <el-checkbox
        size="small"
        :disabled="readOnly"
        :value="!!model.hideIcon"
        @change="value => onChange('hideIcon', value)"
        >{{ i18n['hideIcon'] }}</el-checkbox
      >
    </div>
  </div>
</template>
<script>
export default {
  inject: ['i18n'],
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    onChange: {
      type: Function,
      default: () => null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>
