























import { Component, Vue, Prop } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component({ name: "StatusDetail", components: {} })
export default class extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  model!: any;

  @Prop({
    default() {
      return null;
    },
  })
  onChange!: Function;

  @Prop({ default: false }) readOnly!: Boolean;

  private dataChange(e: any) {
    this.onChange("ins_status", e);
  }

  get config() {
    return UserModule.config;
  }

  get statusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0]);
        return {
          key,
          value: key,
          label: obj[key],
        };
      }) || []
    );
  }
}
