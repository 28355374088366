


























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'InputDropDown' })
export default class extends Vue {
  @Prop({ required: true }) value!: string | number
  @Prop({ default: '#409eff' }) color!: string

  get localValue() {
    return this.value
  }

  set localValue(val: string | number) {
    this.$emit('input', val)
  }
}
