





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ name: 'SingleSelectDropDown' })
export default class extends Vue {
  @Prop({ required: true }) value!: string | number
  @Prop({ default: () => [] }) options!: Array<any>
  @Prop({ default: 'value' }) valueName!: string
  @Prop({ default: 'label' }) labelName!: string
  @Prop({ default: '#409eff' }) color!: string
  @Prop({ default: false }) disabled!: boolean

  get localValue() {
    return this.value
  }

  set localValue(val: string | number) {
    this.$emit('input', val)
  }

  get selectValue() {
    const selectItem = this.options.find(
      item => item[this.valueName] === this.value
    )
    if (selectItem) {
      return selectItem[this.labelName]
    } else {
      this.localValue = this.options[0][this.labelName]
      return this.options[0][this.labelName]
    }
  }

  private handleCommand(command: string | number) {
    this.localValue = command
    this.$emit('change', this.localValue)
  }

  private isDisabled(item: any) {
    return item[this.valueName] === this.localValue
  }
}
