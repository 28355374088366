





















































import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultDetail from './DefaultDetail.vue'
import FlowRuleOptions from './FlowRuleOptions/index.vue'

@Component({
  inject: ['i18n'],
  components: {
    DefaultDetail,
    FlowRuleOptions
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  model!: any

  @Prop({
    default() {
      return null
    }
  })
  onChange!: Function

  @Prop({ default: false }) readOnly!: Boolean

  created() {
    if (!this.model.label) {
      this.onChange('label', '同意')
    }
    if (!this.model.flowProperties) {
      this.onChange('flowProperties', '1')
    }
  }
}
