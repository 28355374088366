























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import EmployeeSelect from '@/components/Select/EmployeeSelect.vue'
import RoleSelect from '@/components/Select/RoleSelect.vue'

@Component({ name: 'NotifyDetail', components: { EmployeeSelect, RoleSelect } })
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    }
  })
  model!: any

  @Prop({
    default() {
      return null
    }
  })
  onChange!: Function

  @Prop({ default: false }) readOnly!: Boolean

  private dataChange() {
    this.onChange('notifyData', this.model.notifyData)
  }

  private deleteNotify(idx: number) {
    this.model.notifyData.splice(idx, 1)
  }
}
