var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-form-container"},[(_vm.data.list.length === 0)?_c('div',{staticClass:"form-empty"},[_vm._v(" "+_vm._s(_vm.$t('fm.description.containerEmpty'))+" ")]):_vm._e(),_c('el-form',{attrs:{"size":_vm.data.config.size,"label-suffix":":","label-position":_vm.data.config.labelPosition,"label-width":_vm.data.config.labelWidth + 'px'}},[_c('draggable',_vm._b({on:{"end":_vm.handleMoveEnd,"add":_vm.handleWidgetAdd},model:{value:(_vm.data.list),callback:function ($$v) {_vm.$set(_vm.data, "list", $$v)},expression:"data.list"}},'draggable',{
        group: 'people',
        ghostClass: 'ghost',
        animation: 200,
        handle: '.drag-widget'
      },false),[_c('transition-group',{staticClass:"widget-form-list",attrs:{"name":"fade","tag":"div"}},[_vm._l((_vm.data.list),function(element,index){return [(element.type === 'grid')?[(element && element.key)?_c('el-row',{key:element.key,staticClass:"widget-col widget-view",class:{active: _vm.selectWidget.key === element.key},attrs:{"type":"flex","gutter":element.options.gutter ? element.options.gutter : 0,"justify":element.options.justify,"align":element.options.align},nativeOn:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_vm._l((element.columns),function(col,colIndex){return _c('el-col',{key:colIndex,attrs:{"span":col.span ? col.span : 0}},[_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleWidgetColAdd($event, element, colIndex)}},model:{value:(col.list),callback:function ($$v) {_vm.$set(col, "list", $$v)},expression:"col.list"}},'draggable',{
                    group: 'people',
                    ghostClass: 'ghost',
                    animation: 200,
                    handle: '.drag-widget'
                  },false),[_c('transition-group',{staticClass:"widget-col-list",attrs:{"name":"fade","tag":"div"}},[_vm._l((col.list),function(el,i){return [(el.key)?_c('widget-form-item',{key:el.key,attrs:{"element":el,"select":_vm.selectWidget,"index":i,"data":col,"data-config":_vm.data},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]})],2)],1)],1)}),(_vm.selectWidget.key === element.key)?_c('div',{staticClass:"widget-view-action widget-col-action"},[_c('i',{staticClass:"iconfont icon-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetDelete(index)}}})]):_vm._e(),(_vm.selectWidget.key === element.key)?_c('div',{staticClass:"widget-view-drag widget-col-drag"},[_c('i',{staticClass:"iconfont icon-drag drag-widget"})]):_vm._e()],2):_vm._e()]:(element.type === 'subform')?[(element && element.key)?_c('el-row',{key:element.key},[_c('el-form-item',{staticClass:"widget-col widget-view",class:{
                  active: _vm.selectWidget.key === element.key,
                  'is-req': element.options.required
                },attrs:{"label-width":element.options.labelWidthStatus ? _vm.data.config.labelWidth + 'px' : '0px',"label":element.options.labelWidthStatus ? element.name : ''},nativeOn:{"click":function($event){return _vm.handleSelectWidget(index)}}},[_c('div',{class:{active: _vm.selectWidget.key === element.key},attrs:{"type":"flex","gutter":element.options.gutter ? element.options.gutter : 0,"justify":element.options.justify,"align":element.options.align}},[_vm._l((element.columns),function(col,colIndex){return _c('el-col',{key:colIndex,attrs:{"span":col.span ? col.span : 0}},[_c('draggable',_vm._b({attrs:{"no-transition-on-drag":true},on:{"end":_vm.handleMoveEnd,"add":function($event){return _vm.handleWidgetColAdd($event, element, colIndex)}},model:{value:(col.list),callback:function ($$v) {_vm.$set(col, "list", $$v)},expression:"col.list"}},'draggable',{
                        group: 'people',
                        ghostClass: 'ghost',
                        animation: 200,
                        handle: '.drag-widget'
                      },false),[_c('transition-group',{staticClass:"widget-col-list",staticStyle:{"min-height":"131px","overflow-x":"auto","white-space":"nowrap"},attrs:{"name":"fade","tag":"div"}},[_vm._l((col.list),function(el,i){return [(el && el.key)?_c('div',{key:el.key,on:{"click":function($event){return _vm.handleSelectWidget(i)}}},[_c('widget-form-item',{attrs:{"element":el,"select":_vm.selectWidget,"index":i,"data":col,"data-config":_vm.data,"is-label":true,"is-table":true},on:{"update:select":function($event){_vm.selectWidget=$event}}})],1):_vm._e()]})],2)],1)],1)}),(_vm.selectWidget.key === element.key)?_c('div',{staticClass:"widget-view-action widget-col-action"},[_c('i',{staticClass:"fa fa-plus",on:{"click":function($event){$event.stopPropagation();return _vm.handleSubformAdd(index)}}}),_c('i',{staticClass:"fa fa-minus",on:{"click":function($event){$event.stopPropagation();return _vm.handleSubformDelete(index)}}}),_c('i',{staticClass:"iconfont icon-trash",on:{"click":function($event){$event.stopPropagation();return _vm.handleWidgetDelete(index)}}})]):_vm._e(),(_vm.selectWidget.key === element.key)?_c('div',{staticClass:"widget-view-drag widget-col-drag"},[_c('i',{staticClass:"iconfont icon-drag drag-widget"})]):_vm._e()],2)])],1):_vm._e()]:[(element && element.key)?_c('widget-form-item',{key:element.key,attrs:{"element":element,"select":_vm.selectWidget,"index":index,"data-config":_vm.data,"data":_vm.data},on:{"update:select":function($event){_vm.selectWidget=$event}}}):_vm._e()]]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }