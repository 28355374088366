var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-clazz":_vm.model.clazz}},[_c('div',{staticClass:"panelTitle"},[_vm._v(_vm._s(_vm.i18n['sequenceFlow']))]),_c('div',{staticClass:"panelBody"},[_c('default-detail',{attrs:{"model":_vm.model,"on-change":_vm.onChange,"read-only":_vm.readOnly}}),_c('div',{staticClass:"panelRow"},[_vm._m(0),_c('el-select',{staticStyle:{"width":"90%","font-size":"12px"},attrs:{"placeholder":"选择流转属性","disabled":_vm.readOnly,"value":_vm.model.flowProperties},on:{"change":function (e) { return _vm.onChange('flowProperties', e); }}},[_c('el-option',{attrs:{"label":"同意","value":"1"}}),_c('el-option',{attrs:{"label":"拒绝","value":"0"}}),_c('el-option',{attrs:{"label":"其他","value":"2"}})],1)],1),_c('div',{staticClass:"panelRow"},[_c('div',[_vm._v(_vm._s(_vm.i18n['sequenceFlow.expression'])+"（兼容SQL条件格式）：")]),_c('el-input',{staticStyle:{"width":"90%","font-size":"12px"},attrs:{"type":"textarea","rows":4,"disabled":_vm.readOnly,"value":_vm.model.rule},on:{"input":function (value) {
            _vm.onChange('rule', value)
          }}})],1),_c('FlowRuleOptions',{attrs:{"model":_vm.model,"on-change":_vm.onChange,"read-only":_vm.readOnly},on:{"submit":function (ref) {
          var rule = ref.rule;
          var data = ref.data;

          _vm.model.rule = rule
          _vm.onChange('rule', rule)
          _vm.onChange('ruleData', data)
        }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" 属性：")])}]

export { render, staticRenderFns }