







































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DefaultDetail from "./DefaultDetail.vue";
import NotifyDetail from "./NotifyDetail.vue";
import StatusDetail from "./StatusDetail.vue";
import EmployeeSelect from "@/components/Select/EmployeeSelect.vue";
import DepartmentSelect from "@/components/Select/DepartmentSelect.vue";
import { variableOptions, levelOptions } from "@/utils/options";
import { userApi, IUsers, IUserParams } from "@/api/user";
import {
  IDepartmentParams,
  departmentApi,
  IDepartments,
  ICreateDepartmentParams,
  IModifyDepartmentParams,
  IDepartment,
} from "@/api/department";
import { IRoleParams, IRoles, RoleAddUserParams, roleApi } from "@/api/role";
@Component({
  inject: ["i18n"],
  components: {
    DefaultDetail,
    NotifyDetail,
    EmployeeSelect,
    DepartmentSelect,
    StatusDetail,
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  model!: any;

  @Prop({
    default() {
      return [];
    },
  })
  tasks!: Array<any>;

  @Prop({
    default() {
      return null;
    },
  })
  onChange!: Function;

  @Prop({ default: false }) readOnly!: Boolean;

  @Prop({
    default() {
      return [];
    },
  })
  templates!: Array<any>;

  @Prop({
    default() {
      return [];
    },
  })
  templatesBase!: Array<any>;

  private variableOptions = variableOptions;
  private levelOptions = levelOptions;

  users = {} as IUsers;
  departments = {} as IDepartments;
  roles = {} as IRoles;
  roleList = [];

  mounted() {
    this.getUsers("");
    this.getDepartments("");
    this.getRoles("");
  }

  getPersons(e: any) {
    if (e === undefined || e === null || e.length <= 1) {
      this.onChange("isCounterSign", false);
      this.onChange("isAutoPass", false);
    }
  }

  assignmentType() {
    this.onChange("isCounterSign", false);
    this.onChange("isAutoPass", false);
  }

  getUsers(query: string) {
    userApi
      .getUsers({ name: query, perPage: 99999, baseOnly: 1 } as IUserParams)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {
        this.$message.warning(`获取用户列表失败, ${e}`);
      });
  }

  getDepartments(query: string) {
    departmentApi
      .getDepartments({ name: query, perPage: 99999, baseOnly: 1, } as IDepartmentParams)
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {
        this.$message.warning(`获取科室列表失败, ${e}`);
      });
  }

  getRoles(query: string) {
    roleApi
      .getRoles({ name: query, perPage: 99999, baseOnly: 1, } as IRoleParams)
      .then((data) => {
        this.roles = data;
      })
      .catch((e) => {
        this.$message.warning(`获取角色列表失败, ${e}`);
      });
  }

  changeValue(e: any) {
    this.onChange("assignValue", e);
  }
}
